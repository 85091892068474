<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
import { template } from '@shein/common-function'

export default {
  name: 'EstimatedNoThresholdNew',
  functional: true,
  components: {
    Icon
  },
  props: {
    price: {
      type: Object,
      default: () => ({
        // 到手价
        amountWithSymbol: '',
        // 门槛价
        addOnAmountWithSymbol: '',
        // 折扣类型到手价
        discountPercent: '',
        usdAmount: ''
      })
    },
    language: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({
        isPaidUser: false,
      })
    },
    countTimer: {
      type: Object,
      default: () => ({
        timeObj: ''
      })
    },
    countdownTips: {
      type: String,
      default: ''
    },
    estimatedInfo: {
      type: Object,
      default: () => ({})
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    isShowAddMore: {
      type: Boolean,
      default: false
    }
  },
  render(h, { props, listeners }) {
    const { language, cssRight, isShowAddMore } = props
    const isEstimatedNothreShowTypeNew1 = props.estimatedInfo?.EstimatedNothreShowType == 'New1'
    let new1Lan = props.language.SHEIN_KEY_PWA_27341 || 'this item only {0} if add-ons {1} more'
    let new2Lan = props.language.SHEIN_KEY_PWA_27342 || 'add-ons {1} more to enjoy {2} off'
    if (cssRight) {
      new1Lan = new1Lan.replace(/\s/g, '&nbsp;')
      new2Lan = new2Lan.replace(/\s/g, '&nbsp;')
    }
    const strWithSpan = (str) => `<span>${str}</span>`
    let value = props.estimatedInfo?.optimalCoupon?.after_coupon_price?.amountWithSymbol
    let text = isEstimatedNothreShowTypeNew1 ? 
      template(strWithSpan(value), strWithSpan(props.price?.needPrice?.amountWithSymbol), new1Lan) :
      template('', strWithSpan(props.price?.needPrice?.amountWithSymbol), strWithSpan(value), new2Lan)
    if (props.countTimer?.timeObj && props.config?.isShowCountdown) {
      const { H, M, S } = props.countTimer?.timeObj || {}
      text += (',' + strWithSpan(`&nbsp;${H}:${M}:${S}`))
    }
    return <div
      class="productPrice-estimatedNoThresholdNew"
      onClick={listeners.handleClick}>
      <div class="productPrice-estimatedNoThresholdNew__left">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4995 1.46656L15.4387 8.64496L2.8121 12.1541L1.12457 4.84151L13.4995 1.46656Z" fill="url(#paint0_linear_81_16466)"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.26392 10.4494L1.13892 12.0812V15.512H16.3264V4.82446H1.13892V8.81764L2.26392 10.4494Z" fill="url(#paint1_linear_81_16466)"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.86401 13.0748L10.0429 7.07446H11.8642L7.68532 13.0748H5.86401ZM6.86434 9.0747C7.41663 9.0747 7.86434 8.62699 7.86434 8.0747C7.86434 7.52242 7.41663 7.0747 6.86434 7.0747C6.31206 7.0747 5.86434 7.52242 5.86434 8.0747C5.86434 8.62699 6.31206 9.0747 6.86434 9.0747ZM10.8644 13.0748C11.4167 13.0748 11.8644 12.627 11.8644 12.0748C11.8644 11.5225 11.4167 11.0748 10.8644 11.0748C10.3122 11.0748 9.86444 11.5225 9.86444 12.0748C9.86444 12.627 10.3122 13.0748 10.8644 13.0748Z" fill="white"/>
          <defs>
            <linearGradient id="paint0_linear_81_16466" x1="3" y1="10" x2="2.36447" y2="4.75896" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF8A69"/>
              <stop offset="1" stop-color="#FA6338"/>
            </linearGradient>
            <linearGradient id="paint1_linear_81_16466" x1="6.49244" y1="4.98166" x2="8.9215" y2="11.3712" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF8A69"/>
              <stop offset="1" stop-color="#FA6338"/>
            </linearGradient>
          </defs>
        </svg>
        <p class={{
          'productPrice-estimatedNoThresholdNew__left-text': true,
          'productPrice-estimatedNoThresholdNew__left-text_ar': cssRight
        }} domPropsInnerHTML={text} />
      </div>
      {isShowAddMore ? <div class="productPrice-estimatedNoThresholdNew__right">
        <p>{language.SHEIN_KEY_PWA_15091}</p>
        <Icon name="sui_icon_more_right_12px" size="12px" is-rotate={cssRight} />
      </div> : null
      }
    </div>
  }
}
</script>

<style lang="less">
.productPrice-estimatedNoThresholdNew {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(269.95deg, #FFF6F3 0.04%, #FFE6DD 99.96%);
  min-height: .8rem;
  padding: 6px 12px;
  &__left {
    display: flex;
    align-items: center;
    margin-right: .32rem;
    & > svg {
      display: block;
      min-width: 18px;
      margin-right: .2133rem;
    }
  }
  &__left-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #000;
    text-align: left;
    & > span {
      color: #FA6338;
      font-weight: 600;
    }
  }
  &__left-text_ar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__right {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #000;
  }
}
</style>
