<script lang="jsx">
/**
 * @props
 *  @param index
 *  @param relatedParams
 */
export default {
  name: 'SizeSelectRelatedLabel',
  functional: true,
  props: {
    index: {
      type: Number,
      default: 0,
    },
    relatedParams: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  render(h, { props, listeners }) {
    const { index, config, relatedParams } = props
    const { relateGoodType, relateGood, relatedSizeMap } = relatedParams
    const style = { order: relateGoodType === 1 ? 999 : 0 }
    const sizeText = relateGood.sizeText
    
    const getRelatedRenderText = () => {
      if (!sizeText?.length) return ''
      let text = sizeText.map((s) => {
        if (!config?.isNewSizeLocal || !relatedSizeMap?.[s]) return `${s}`
        if (relatedSizeMap[s]) {
          const shouldNoDefaultSizeAbt = ['local_size_no_default', 'default_size_no_default'].includes(config?.defaultlocalsize)
          if (shouldNoDefaultSizeAbt) return relatedSizeMap[s]
          return `${relatedSizeMap[s]}${config?.shouldWeakenDefaultSize ? `<span style="color: #959595; font-size: 12px;">(${s})</span>` : `(${s})`}`
        }
      })
      return text.join(' ')
    }

    return (
      <li
        {...{ directives: [{ name: 'ada', value: { level: 1, pos: index } }, { name: 'expose', value: { id: '1-6-1-5' } }] }}
        class="goods-size__relate-good goods-size__sizes-item"
        da-event-click="1-6-1-6"
        style={style}
        role="text"
        aria-label={relateGood.sizeText.join(' ')}
        v-on:click={() => listeners['handlerOpenAdd'](relateGood)}
      >
        <span domPropsInnerHTML={getRelatedRenderText()}></span>
        <i
          class={['iconfont icon-price_px_-', { 'to-right': config?.from != 'detailPage' }]}
          aria-hidden="true"
        ></i>
      </li>
    )
  },
}
</script>
