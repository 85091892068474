<script lang="jsx">
/**
 * sizegourp放在size后面
 * 
 * @props
 *  @param index
 *  @param relatedParams
 */
export default {
  name: 'SizeRelationLabel',
  functional: true,
  props: {
    index: {
      type: Number,
      default: 0,
    },
    attrLength: {
      type: Number,
      default: 0,
    },
    sizeGroupItem: {
      type: Object,
      default: () => ({}),
    },
    goodsId: {
      type: String,
      default: '',
    }
  },
  render(h, { props, listeners }) {
    const { sizeGroupItem, attrLength, index } = props
    const style = { order: attrLength + index + 1 }
    const goodItem = {
      ...sizeGroupItem,
      goods_id: sizeGroupItem.goodsId,
      hideSizeGroupeSize: true,
      activityFrom: 'sizegroup',
    }
    const getSizegroupAnalysis = ({ type = 'click' }) => {
      let goods_size_list = '' // 拼接规则 goods_id`size_group`theme_type
      const { theme_type = '', merge_theme_type = '', displayDesc, goodsId } = sizeGroupItem
      goods_size_list = `${goodsId}\`${displayDesc}\`${merge_theme_type || theme_type}`
      const data = {
        location: props.from === 'popup' ? 'popup' : 'page',
        source_goods_id: props.goodsId,
        size_group_style: 'size_attr',
        goods_size_list
      }
      return {
        id: type === 'click' ? '1-6-1-185' : '1-6-1-184',
        data,
        code: 'after_size_group_code'
      }
    }
    const textContent = () =>
      (
        <span
          v-expose={getSizegroupAnalysis({ type: 'expose' })} 
          v-tap={getSizegroupAnalysis({ type: 'click' })} 
          class="goods-size__sizes-item-text"
        >
          { sizeGroupItem.displayDesc }
        </span>
      )
    return (
      <li
        class="goods-size__sizes-item size-group__sizes-item"
        role="text"
        style={style}
        v-on:click={() => listeners['handlerOpenAdd'](goodItem)}
      >
        {textContent()}
        <i class="suiiconfont sui_icon_more_right_12px_2"></i>
      </li>
    )
  },
}
</script>

<style lang="less" scoped>
.size-group {
  &__sizes-item{
    display: flex;
    max-width: 46%;
    .goods-size__sizes-item-text {
      .text-overflow();
    }
  }
}
</style>
