<script lang="jsx">
import SizeSelectLabel from './SizeSelectLabel'
import SizeSelectRelatedLabel from './SizeSelectRelatedLabel'
/**
 * @props
 *  @param { Number } sizeRecommendType 尺码推荐类型
 *  @param { Boolean } bold 标题加粗
 */
export default {
  name: 'SizeSelectItem',
  functional: true,
  inject: ['language', 'sizeRecommendType', 'customerStyle', 'config'],
  props: {
    bold: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    currentTabLocalIsActive: {
      type: Boolean,
      default: false,
    },
    sizeGroupPosition: {
      type: String,
      default: undefined,
    },
    sizeInfoIndex: {
      type: Object,
      default: () => ({}),
    },
    relatedParams: {
      type: Object,
      default: () => ({}),
    },
    localSelectParams: {
      type: Object,
      default: () => ({}),
    },
    saleAttrList: {
      type: Object,
      default: () => ({}),
    },
    sizeRecommendContent: {
      type: Function,
      default: () => {}
    },
    sizeGroupContent: {
      type: Function,
      default: () => {}
    },
    isOnSale: {
      type: Boolean,
      default: true
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, { props, injections, listeners }) {
    const { language, sizeRecommendType, customerStyle } = injections
    // fix: inject 没有 reactive， 使用 props 里的 config，保证 reactive
    const { config } = props
    const { bold, overflowX = false } = customerStyle
    const {
      item = {},
      sizeInfoIndex,
      currentTabLocalIsActive,
      sizeGroupPosition,
      relatedParams,
      localSelectParams,
      sizeRecommendContent,
      sizeGroupContent,
      isOnSale
    } = props
    const { showLocalTextSelect, showLocalText, country } = localSelectParams
    const { attr_id: attrId, attr_name: attrName } = item
    const { attr_value_list = [] } = item

    const localTextSelectStatus = attrId == 87 && showLocalTextSelect && config?.isNewSizeLocal
    const localTextStatus = attrId == 87 && showLocalText
    const hideRelatedSize = !!config?.hideRelatedSize

    const getSelectLocalAnalysis = ({ id , type}) => {
      const choose_type = country ? 1 : 0
      const style_type = type
      return {
        id,
        data: {
          choose_type,
          style_type
        },
      }
    }

    const selectLocalEl = () => {
      if (localTextSelectStatus) {
        if(config.showOnlyOneLocalSize){
          return (<span 
            class="goods-size__standard"
            {...{
              directives: [{ name: 'expose', value: getSelectLocalAnalysis({ id: '1-6-1-104', type: 'tab' }) }],
            }}
          >
            <span
              class={!currentTabLocalIsActive ? 'goods-size__black' : ''}
              v-on:click={()=>{
                listeners['clickLocalTextSelect']('')
              }}
            >
              { language.SHEIN_KEY_PWA_33076 || 'Standard' }
            </span>
            <span 
              class={currentTabLocalIsActive ? 'goods-size__black' : ''}
              v-on:click={()=>{
                listeners['clickLocalTextSelect'](config?.sizeRule?.country_code)
              }}
            >
              { config?.sizeRule?.country_code } 
            </span>
          </span>)
        }
        return (
          <span
            {...{
              directives: [{ name: 'expose', value: getSelectLocalAnalysis({ id: '1-6-1-104', type: 'list' }) }],
            }}
            class="goods-size__title-local"
            v-on:click={listeners['clickLocalTextSelect']}
          >
            {country || language.SHEIN_KEY_PWA_23344}
            <i
              class="suiiconfont sui_icon_more_down2_12px"
              aria-hidden="true"
            ></i>
          </span>
        )
      } else if (localTextStatus && country) {
        return <span>({country})</span>
      }
      return null
    }

    const itemTitleEl = () => (
      <div
        aria-hidden="true"
        class={['goods-size__title', { 'self-size-recommend': sizeRecommendType == 1 }]}
      >
        <div class={['goods-size__title-txt', { 'goods-size__title-bold': bold }]}>
          {attrName}
          {selectLocalEl()}
        </div>
        {sizeRecommendContent(attrId)}
      </div>
    )


    const relatedLabelItem = () => {
      const show = !hideRelatedSize && attrId == 87 && [0, 1].includes(relatedParams?.relateGoodType) && !sizeGroupPosition
      if (!show) return null
      return (<SizeSelectRelatedLabel
        index={attr_value_list.length}
        config={config}
        relatedParams={relatedParams}
        v-on:handlerOpenAdd={listeners['handlerOpenAdd']}
      />)
    }

    const sizeGroupLabelItem = () => {
      const show = attrId == 87
      if (!show) return null
      return sizeGroupContent(attr_value_list.length)
    }

    const labelItem = attr_value_list.map((label, index) => (
      <SizeSelectLabel
        attrName={attrName}
        attrId={attrId}
        label={label}
        index={index}
        config={config}
        is-on-sale={isOnSale}
        sizeInfoIndex={sizeInfoIndex}
        v-on:sizeClick={listeners['sizeClick']}
      />
    ))

    const itemContentEl = () => (
      <div>
        <ul class={[
          'SIZE_ITEM_HOOK',
          'goods-size__sizes choose-size', 
          { 'on-line__style': overflowX }]}>
          {relatedLabelItem()}
          {labelItem}
          {sizeGroupLabelItem()}
        </ul>
      </div>
    )

    return (
      <div>
        {itemTitleEl()}
        {itemContentEl()}
      </div>
    )
  },
}
</script>
