var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"goods-size-group__wrapper",class:{'goods-size-group__relation-wrapper': _vm.findPerfectFitScene}},[_c('div',{staticClass:"goods-size-group__title",attrs:{"aria-hidden":"true"},on:{"click":_setup.controlSlideExpandStatus}},[_c('div',{staticClass:"goods-size__title-txt",class:{
        'goods-size__title-bold': _vm.from === 'detail',
        'goods-size-group__title-txt': _vm.findPerfectFitScene
      }},[_vm._v("\n      "+_vm._s(_setup.sizeGroupTitle)+"\n    ")]),_vm._v(" "),(_vm.findPerfectFitScene)?_c('span',{directives:[{name:"expose",rawName:"v-expose",value:({
        id: '1-6-1-196',
        data: {
          location: _vm.from === 'detail' ? 'page' : 'popup',
          goods_id: _vm.goodsId,
        }
      }),expression:"{\n        id: '1-6-1-196',\n        data: {\n          location: from === 'detail' ? 'page' : 'popup',\n          goods_id: goodsId,\n        }\n      }"}],staticClass:"more-icon"},[_c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(!_setup.perfectFitShow),expression:"!perfectFitShow"}],attrs:{"name":"sui_icon_more_down_12px","color":"#222","size":"14px"}}),_vm._v(" "),_c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(_setup.perfectFitShow),expression:"perfectFitShow"}],attrs:{"name":"sui_icon_more_up_12px","color":"#222","size":"14px"}})],1):_vm._e()]),_vm._v(" "),(_vm.findPerfectFitScene && _vm.cccOnlyRelatedGoods.length)?[_c(_setup.SSlide,{attrs:{"visible":_setup.perfectFitShow},on:{"update:visible":function($event){_setup.perfectFitShow=$event}}},[_c('div',{directives:[{name:"expose",rawName:"v-expose",value:(_setup.getAnalysisData({ type: 'expose' })),expression:"getAnalysisData({ type: 'expose' })"}],staticClass:"goods-size-group__content perfect-fit"},[_c('ul',{staticClass:"goods-size__sizes choose-size"},_vm._l((_vm.cccOnlyRelatedGoods),function(sizeGroupListItem){return _c('li',{directives:[{name:"tap",rawName:"v-tap",value:(_setup.getAnalysisData({ type: 'click', item: sizeGroupListItem })),expression:"getAnalysisData({ type: 'click', item: sizeGroupListItem })"}],key:sizeGroupListItem.goodsId,class:{
              'goods-size__sizes-item': true,
            },attrs:{"type":"text"},on:{"click":function($event){return _setup.clickSizeGroupItem(sizeGroupListItem)}}},[_c('span',{staticClass:"goods-size__sizes-item-text"},[_vm._v("\n              "+_vm._s(sizeGroupListItem.displayDesc)+"\n            ")]),_vm._v(" "),_c('i',{staticClass:"suiiconfont sui_icon_more_right_12px_2"})])}),0)])])]:[_c('div',{directives:[{name:"expose",rawName:"v-expose",value:(_setup.getAnalysisData({ type: 'expose' })),expression:"getAnalysisData({ type: 'expose' })"}],staticClass:"goods-size-group__content"},[_c('ul',{staticClass:"goods-size__sizes choose-size"},_vm._l((_setup.sizegroupStyleData),function(sizeGroupListItem){return _c('li',{directives:[{name:"tap",rawName:"v-tap",value:(_setup.getAnalysisData({ type: 'click', item: sizeGroupListItem })),expression:"getAnalysisData({ type: 'click', item: sizeGroupListItem })"}],key:sizeGroupListItem.goodsId,class:{
            'goods-size__sizes-item': true,
            'size-active': sizeGroupListItem.isCurrentGroup
          },attrs:{"type":"text"},on:{"click":function($event){return _setup.clickSizeGroupItem(sizeGroupListItem)}}},[_c('span',{staticClass:"goods-size__sizes-item-text"},[_vm._v("\n            "+_vm._s(sizeGroupListItem.displayDesc)+"\n          ")]),_vm._v(" "),(_vm.sizegroupAfterSize)?_c('i',{staticClass:"suiiconfont sui_icon_more_right_12px_2"}):_vm._e()])}),0)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }