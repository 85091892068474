var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productPrice-estimatedPercent",class:{ 'productPrice-estimatedPercent_percentTypeStyleWrap': _vm.isWrapStyle },style:({
    background: _vm.strategyStyle.mainBackground,
    color: _vm.strategyStyle.mainColor
  }),on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[_c('div',{staticClass:"productPrice-estimatedPercent__container"},[_c('div',{staticClass:"productPrice-estimatedPercent__estimated"},[_vm._v("\n      "+_vm._s(_vm.isAb && _vm.isMiddleastNew? (_vm.language.SHEIN_KEY_PWA_33261 || 'with special coupon') : _vm.language.SHEIN_KEY_PWA_26364)+"\n      "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isWrapStyle),expression:"!isWrapStyle"}],staticClass:"productPrice-estimatedPercent__left-arrow",class:{'productPrice-estimatedPercent__left-arrow_cssRight': _vm.cssRight}},[_c('svg',{attrs:{"width":"5px","height":"5px","viewBox":"0 0 5 5","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('g',{attrs:{"id":"页面-1","stroke":"none","stroke-width":"1","fill":"none","fill-rule":"evenodd"}},[_c('path',{attrs:{"id":"形状结合","d":"M5,5 L0,5 L0.216889622,4.99538049 C2.87769329,4.88181811 5,2.6887547 5,-2.22044605e-16 L5,5 Z","fill":_vm.strategyStyle.mainBackground}})])])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWrapStyle),expression:"isWrapStyle"}],staticClass:"productPrice-estimatedPercent__top-arrow",style:({
          background: _vm.strategyStyle.mainBackground,
        })})]),_vm._v(" "),_c('div',{staticClass:"productPrice-estimatedPercent__percent",style:({
        background: _vm.strategyStyle.percentBackground,
        color: _vm.strategyStyle.percentColor,
        'font-weight': _vm.strategyStyle.percentFontWeight
      })},[_vm._v("\n      "+_vm._s(_vm.discountPercent)+"\n    ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }