<script lang="jsx">
export default {
  name: 'SizeFeedBackEnter',
  functional: true,
  inject: ['language'],
  render(h, { injections, listeners }) {
    const { language } = injections
    return (
      <div class="size-feedback_title">
        <div v-on:click={listeners['onSizeFeedbackBtnClick']}>
          {language.SHEIN_KEY_PWA_21097} {language.SHEIN_KEY_PWA_21088}
          <i class="suiiconfont sui_icon_more_right_12px_2 more-icon" />
        </div>
      </div>
    )
  },
}
</script>

<style lang="less">
.size-feedback {
  &_title {
    color: #666;
    line-height: 1;
    margin-bottom: .16rem;
    display: flex;
    .more-icon {
      position: relative;
      top: 1px;
    }
  }
}
</style>
