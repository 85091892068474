<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
export default {
  name: 'LowestPriceTips',
  functional: true,
  components: {
    Icon
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  render(h, { props }) {
    const { text } = props
    return (
      <div class="lowest-tips__container">
        <Icon name="sui_icon_lowest_price_12px_2" size="12px" color="#C44A01"/>
        <span class="text"> { text } </span>
      </div>
    )
  }
}
</script>

<style lang="less">
.lowest-tips {
  &__container {
    display: inline-flex;
    align-items: center;
    .font-dpr(20px);
    color: @sui_color_discount_dark;
    .text {
      padding-left: 4/37.5rem;
    }
  }
}
</style>
