<template>
  <component
    :is="currentComp"
    :config="config"
    :price="price"
    :language="language"
    :count-timer="countTimer"
    :countdown-tips="countdownTips"
    :is-percent-type-style-wrap="isPercentTypeStyleWrap"
    :estimated-info="estimatedInfo"
    :cssRight="cssRight"
    :is-show-add-more="isShowAddMore"
    :is-belt-type="isBeltType"
    :is-belt-type-brand="isBeltTypeBrand"
    :is-floor-type="isFloorType"
    :is-quick-add="isQuickAdd"
    :is-quick-add-belt="isQuickAddBelt"
    :compliance-mode="estimatedInfo.complianceMode"
    :compliance-mode-de="estimatedInfo.complianceModeDe"
    :is-satisfied-buy-multiple="isSatisfiedBuyMultiple"
    :has-link="hasLink"
    :is-middleast-new="estimatedInfo?.isMiddleastNew"
    :is-ab="estimatedInfo?.isAb || estimatedInfo?.isAbCoexist"
    @handleClick="handleClick"
  />
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { CountDown, debounce } from '@shein/common-function'
import EstimatedTagOld from './EstimatedTagOld'
import EstimatedTagNew1 from './EstimatedTagNew1'
import EstimatedNoThresholdNew from './EstimatedNoThresholdNew'

daEventCenter.addSubscriber({ modulecode: '1-6-1' })
const { GB_cssRight } = gbCommonInfo

if(typeof window !== 'undefined') {
  window._exposeEstimatedTagFn_ = debounce({
    func: (args) => {
      daEventCenter.triggerNotice(args)
    },
    wait: 1500
  })
}

export default {
  name: 'EstimatedTag',
  props: {
    scene: {
      type: String,
      default: ''
    },
    // 折扣类型到手价标签换行了
    isPercentTypeStyleWrap: {
      type: Boolean,
      default: false
    },
    analysisData: {
      type: Object,
      default: () => ({
        location: 'popup', // 商详 page 加车弹窗 popup
      })
    },
    language: {
      type: Object,
      default: () => ({})
    },
    priceUpdate: {
      type: Number,
      default: 0
    },
    promotionInfo: {
      type: Array,
      default: () => []
    },
    estimatedInfo: {
      type: Object,
      default: () => ({})
    },
    isBeltType: {
      type: Boolean,
      default: false
    },
    isQuickAddBelt: {
      type: Boolean,
      default: false
    },
    isQuickAdd: {
      type: Boolean,
      default: false
    },
    isBeltTypeBrand: {
      type: Boolean,
      default: false
    },
    isEstimatedNoThresholdNewType: {
      type: Boolean,
      default: false
    },
    isShowAddMore: {
      type: Boolean,
      default: false
    },
    isFloorType: {
      type: Boolean,
      default: false
    },
    hasLink: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      countTimer: {},
      cssRight: GB_cssRight
    }
  },
  computed: {
    config() {
      return this.estimatedInfo?.estimatedCompProps.config
    },
    price() {
      return this.estimatedInfo?.estimatedCompProps.price
    },
    isEstimatedNothreShowTypeAbtNew() {
      return ['New1', 'New2'].includes(this.config.EstimatedNothreShowType)
    },
    isSatisfiedBuyMultiple() {
      return this.estimatedInfo?.isSatisfiedBuyMultiple
    },
    currentComp() {
      if (this.isEstimatedNothreShowTypeAbtNew && this.price.addOnAmountWithSymbol && this.price.needPrice) {
        if (this.estimatedInfo.isAbCoexist) {
          return this.isEstimatedNoThresholdNewType ? EstimatedNoThresholdNew : EstimatedTagNew1
        }
        return this.isEstimatedNoThresholdNewType ? EstimatedNoThresholdNew : null
      }
      if (this.isPercentType) return EstimatedTagNew1
      return EstimatedTagOld
    },
    isPercentType () {
      return !!this.price.discountPercent
    },
    countdownTips () {
      const { H, M, S } = this.countTimer?.timeObj || {}
      let spanTxt = txt => `<span>${txt}</span>`
      return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S)
    }
  },
  watch: {
    priceUpdate (val) {
      if (val) {
        this.triggerExpose()
      }
    },
    promotionInfo: {
      handler() {
        this.initTimer()
      },
      deep: true
    },
    estimatedInfo: {
      handler() {
        this.initTimer()
      },
      deep: true
    }
  },
  mounted () {
    this.initTimer()
    // 新增scene参数区分来源埋点，新改造组件直接监听goodsId用作skc切换事件，后续改造去除掉
    if (this.analysisData.location === 'page' && this.scene !== 'prices') return
    this.triggerExpose()
  },
  methods: {
    triggerExpose () {
      if (this.isFloorType) return
      let { isAb, isAbCoexist, isSatisfied, isSatisfiedBuyMultiple } = this.estimatedInfo || {}
      let { addOnAmountWithSymbol } = this.price
      let threshold
      if (this.estimatedInfo) {
        if (isAb) {
          threshold = 3
        } else if (isAbCoexist) {
          threshold = isSatisfied ? 4 : 5
        } else if (isSatisfiedBuyMultiple) {
          threshold = 6
        } else {
          threshold = isSatisfied ? 1 : 2
        }
      }
      
      window._exposeEstimatedTagFn_({
        daId: '1-6-1-143',
        extraData: {
          ...this.analysisData,
          threshold, // 满足门槛上报为 1 ，未满足门槛上报为2， ab 价上报3
          estimated_price: this.price.usdAmount, // 到手价 amountWithSymbol
          timelimit: this.config.isShowCountdown ? 1 : 0,
          showtype: this.price.discountPercent ? 1 : 0,
          clubprice: this.estimatedInfo.isNewSheinClubShipping ? 1 : 0,
          ...(addOnAmountWithSymbol ? { nothroshowtype: this.isEstimatedNothreShowTypeAbtNew ? 1 : 0 } : {})
        }
      })
    },
    handleClick () {
      if (!this.hasLink) return
      let { isAb, isAbCoexist, isSatisfied, isSatisfiedBuyMultiple } = this.estimatedInfo || {}
      let { addOnAmountWithSymbol } = this.price
      let threshold
      if (this.estimatedInfo) {
        if (isAb) {
          threshold = 3
        } else if (isSatisfiedBuyMultiple) {
          threshold = 6
        } else if (isAbCoexist) {
          threshold = isSatisfied ? 4 : 5
        } else {
          threshold = isSatisfied ? 1 : 2
        }
      }
      daEventCenter.triggerNotice({
        daId: '1-6-1-142',
        extraData: {
          ...this.analysisData,
          threshold, // 满足门槛上报为 1 ，未满足门槛上报为2， ab 价上报3
          estimated_price: this.price.usdAmount,
          timelimit: this.config.isShowCountdown ? 1 : 0,
          showtype: this.price.discountPercent ? 1 : 0,
          ...(addOnAmountWithSymbol ? { nothroshowtype: this.isEstimatedNothreShowTypeAbtNew ? 1 : 0 } : {})
        }
      })
      this.$emit('clickLink')
    },
    initTimer () {
      if(!this.config.isShowCountdown) return
      // 倒计时开始时间
      this.countTimer = new CountDown()
      const beginTime = this.config.estimatedPriceEndTime * 1000 - new Date().getTime()
      if (beginTime <= 0) {
        beginTime == 0
      }

      // 初始化倒计时
      this.countTimer?.start({
        seconds: beginTime / 1000,
        endFunc: debounce({
          func: () => {
            location.reload()
          }
        })
      })
    }
  },
}

</script>
