<script lang="jsx">
import { CountDown, debounce } from '@shein/common-function'

export default {
  name: 'EstimatedCountdown',
  props: {
    estimatedPriceEndTime: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      countTimer: {
        timeObj: {
          H: '00',
          M: '00',
          S: '00'
        }
      }
    }
  },
  mounted () {
    this.initTimer()
  },
  methods: {
    initTimer () {
      // 倒计时开始时间
      this.countTimer = new CountDown()
      let beginTime = this.estimatedPriceEndTime * 1000 - new Date().getTime()
      if (beginTime <= 0) {
        beginTime == 0
      }
      // 初始化倒计时
      this.countTimer?.start({
        seconds: beginTime / 1000,
        endFunc: debounce({
          func: () => {
            location.reload()
          }
        })
      })
    }
  },
  render () {
    let { countTimer } = this
    const { H, M, S } = countTimer?.timeObj || {}
    return <div class="estimated-countdown">
      <i class="suiiconfont sui_icon_time_15px estimated-countdown__icon"></i>
      <p>{H}</p>
      <p class="estimated-countdown__colon">:</p>
      <p>{M}</p>
      <p class="estimated-countdown__colon">:</p>
      <p>{S}</p>
    </div>
  }
}

</script>

<style lang="less">
.estimated-countdown {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: #FA6338;
  font-size: 11px;
  white-space: nowrap;
  &__icon {
    font-size: 11px !important;/* stylelint-disable-line declaration-no-important */
    margin-right: 3px;
  }
  &__colon {
    margin-right: 1px;
  }
}

</style>
