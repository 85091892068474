import schttp from 'public/src/services/schttp'

const CACHE_KEY = 'groupList'
export const getGroupList = async () => {
  if (typeof localStorage === 'undefined') return []

  let groupList = JSON.parse(localStorage.getItem(CACHE_KEY)) || []
  if (!groupList || groupList.length < 1) {
    const json = await schttp({ url: '/api/user/wishlist/group/list/get' })
    if (
      json &&
      json.data &&
      Number(json.data.code) === 0 &&
      json.data.info &&
      json.data.info.groupList
    ) {
      groupList = json.data.info.groupList
      localStorage.setItem(CACHE_KEY, JSON.stringify(groupList))
    }
  }

  return groupList
}
