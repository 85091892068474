<script lang="jsx">
export default {
  name: 'SizeTipsContent',
  functional: true,
  inject: ['language'],
  props: {
    isShowSizeTipsText: {
      type: Boolean,
      default: false,
    },
    tipsCombo: {
      type: Object,
      default: () => ({}),
    },
    trueToSize: {
      type: String,
      default: '',
    },
    isShowSizeTips: {
      type: Object,
      default: () => ({}),
    },
    noJump: {
      type: Boolean,
      default: false,
    },
    isFromPopup: {
      type: Boolean,
      default: false,
    },
    fsAbt: {
      type: Object,
      default: () => ({}),
    },
    tipsFrom: {
      type: String,
      default: '',
    },
    isFromPdc: {
      type: Boolean,
      default: false,
    }
  },
  render(h, { props, injections, listeners }) {
    const { language } = injections
    const { trueToSize, tipsCombo, isShowSizeTips, isShowSizeTipsText, noJump, isFromPopup, fsAbt } = props
    const { showTrueToSizeStrong, showTrueToSizeStrongText, true_size_text } = isShowSizeTips
    const { showHeelHeight, cmInchInfo, sizeTipsBold, hiddenHeelHeightTipsAbt, cccTrueToSizeText } = tipsCombo
    const { tipsshow } = fsAbt?.goodsdetailTips?.param || {}
    const shouldShowTrueToSizeStrong = !cccTrueToSizeText && showTrueToSizeStrong && tipsshow && tipsshow === 'default'

    const title = () => {
      if (!trueToSize?.trim()) return null
      return sizeTipsBold ? [
        <span class="goods-size__tips-bold">{language.SHEIN_KEY_PWA_16772}: </span>,
        trueToSize
      ] : `${language.SHEIN_KEY_PWA_16772}: ${trueToSize}`
    }

    const tips = showHeelHeight && !hiddenHeelHeightTipsAbt ? cmInchInfo : ''

    // const isShowSizeTipsText =
    //   !defaultTrueToSizeText && isShowSizeTips?.isHitExperiment
    // const isShowSizeTipsText = !defaultTrueToSizeText && trueToSize

    const getShowSizeTipsTextAnalysis = ({ id, isStrong }) => {
      const type = isStrong ? 3 : isShowSizeTips.isLargeSizeTips ? 1 : 2
      return {
        id,
        data: {
          type,
        },
      }
    }

    const iconContent = () =>
      isShowSizeTipsText ? (
        <i
          v-expose={getShowSizeTipsTextAnalysis({ id: '1-6-1-113' })}
          class="goods-size__undersize-deviation-tips-icon suiiconfont suiiconfont-critical sui_icon_more_right2_16px"
        ></i>
      ) : null

    const clickFn = (key, ...args) => (() => {
      if (noJump && key === 'openReviews') return
      listeners[key]?.(...args)
    })
      
    const trueToSizeStrongContent = () => {
      const trueToSizeStrong = shouldShowTrueToSizeStrong ? (
        <span 
          v-expose={getShowSizeTipsTextAnalysis({ id: '1-6-1-113', isStrong: true })}
          class={['goods-size__undersize-deviation-tipsstrong', trueToSize ? 'new-line' : '' ]}
          v-on:click_stop_prevent={clickFn('openReviews')}
        >
          <span class="tipsstrong-span"><i class="suiiconfont sui_icon_like_12px like-icon"></i>{true_size_text}</span>
          {showTrueToSizeStrongText}
          {!noJump ? <i
            class="suiiconfont sui_icon_more_right_12px_2 more-icon"
          ></i> : null}
        </span>
      ) : null
      return trueToSizeStrong
    }
    return (
      <div
        class="goods-size__undersize-deviation-tips"
        aria-hidden="true"
        v-on:click={clickFn('openSizeGuideFromSizeTips', isFromPopup ? 'cart_tips' : 'detail_tips')}
      >
        <span
        >
          {title()}
        </span>
        <span dir="ltr">{tips}</span>
        {iconContent()}
        {trueToSizeStrongContent()}
      </div>
    )
  },
}
</script>
